import React from "react"
import { Link } from "gatsby"
import { App } from "@bovieran/app"
import WPAJAX from "@businessandemotions/wpajax"

var Ajax = new WPAJAX({ endpoint: process.env.GATSBY_WP_AJAX_URL })

//App.WordPress = WordPress
//App.API.media = id => WordPress.media().id(id)
App.API.ajax = Ajax
App.init()

App.Link = function(props) {
  if (/^https?:\/\//.test(props.href)) {
    return <a {...props} />
  }
  const filteredProps = Object.assign({}, props, {
    target: undefined,
    href: undefined,
  })
  return <Link to={props.href} {...filteredProps} />
}

export default props => <App {...props} />
